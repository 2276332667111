import React from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Joins.css";
import { useState } from "react";

const Joins = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_jp692pr",
        "template_56c8nke",
        form.current,
        "ffEA4ETQ_rX82YGVI"
      )
      .then(
        (result) => {
          console.log(result.text); 
          setValue("")
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const [value, setValue]=useState("")

  return (
    <div className="joins" id="joins">
      <div className="left-j">
        <hr />
        <div>
          <span className="stroke-text"> READY TO </span>
          <span> LEVEL UP </span>
        </div>
        <div>
          <span> YOUR BODY </span>
          <span className="stroke-text"> WITH US? </span>
        </div>
      </div>
      <div className="right-j">
        <form ref={form} className="from-container" onSubmit={sendEmail}>
          <input
            type="email"
            name="user_email"
            placeholder="Please Enter Your Email"  value={value} onChange={()=>{setValue()}}
          />
          <button className="btn btn-j">Join Now</button>
        </form>
      </div>
    </div>
  );
};

export default Joins;
