import React from "react";
import Header from "../Header/Header";
import "./Hero.css";
import calories from "../../assets/calories.png";
import heart from "../../assets/heart.png";
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import { motion } from "framer-motion";
import NumberCounter from "number-counter";

const Hero = () => {
  const transition={type:'spring',duration:3} 
  const mobile= window.innerWidth<=768?true:false;
  return (
    <div className="hero" id="hero">
      <div className="blur hero-blur"></div>
      {/* Left section */}
      <div className="left-h">
        <Header />

        {/* the best add */}

        <div className="the-best-add">
          <motion.div
          initial={{left:mobile?'150px':'238px'}}
          whileInView={{left:'8px'}}
          transition={{...transition,type:'tween'}}
          
          ></motion.div>
          <span>the best fitness clube in the town</span>
        </div>

        {/* Hero text */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape </span>
            <span>Your</span>
          </div>
          <div>
            <span>Ideal body</span>
          </div>
          <div>
            <span>
              In here we will traine you and your body to achieve your dream
              shape of your body so come over here to know further more
            </span>
          </div>
        </div>
        {/* firguires */}
        <div className="figures">
          <div>
            <span><NumberCounter
                  start={100}
                  end={140}
                  delay={2}  prefix="+"/></span>
            <span>Expert coaches</span>
          </div>
          <div>
            <span><NumberCounter
                  start={900}
                  end={987}
                  delay={7}  prefix="+"/></span>
            <span>members joined</span>
          </div>
          <div>
            <span><NumberCounter
                  start={0}
                  end={50}
                  delay={3}  prefix="+"/></span>
            <span>fitnesss programs</span>
          </div>
        </div>

        {/* hero buttons */}
        <div className="hero-buttons">
          <button className="btn">Get Started</button>
          <button className="btn">Learn More</button>
        </div>
      </div>

      {/* Right section */}
      <div className="right-h">
        <button className="btn">Join Now</button>
        <motion.div 
          initial={{right:'-2rem'}}
          whileInView={{right:'4rem'}}
          transition={transition}
        
        className="heart-rate">
          <img src={heart} alt="" />
          <span>Heart Rate</span>
          <span>116 bpm</span>
        </motion.div>
        {/* Hero images */}
        <img className="hero_img" src={hero_image} alt="" />
        <motion.img 
        initial={{right:'11rem'}}
        whileInView={{right:'20rem'}}
        transition={transition}
        className="hero_img_back" src={hero_image_back} alt="" />
        <motion.div
        initial={{right:'37rem'}}
        whileInView={{right:'28rem'}}
        transition={transition}
        className="calories">
          <img src={calories} alt="" />
          <div>
            <span>Calories Burned</span>
            <span>240 kcal</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
