import React from "react";
import "./Header.css";
import logo from "../../assets/logo.png";
import bars from "../../assets/bars.png";
import { useState } from "react";
import { Link } from "react-scroll";
 
const Header = () => {  
  const [mobile,setMobile]=useState(window.innerWidth<=768?true:false)

  return (
    <div className="header">
      <img className="logo" src={logo} alt="" />
        
         {mobile?  <img style={{backgroundColor:'darkgrey',padding:'7px',height:'1.5rem',width:'1.5rem',borderRadius:'5px',cursor:'pointer'}}   onClick={()=>setMobile(false)} src={bars} alt="" />:
         <ul className="header-menu">
          
            <li><Link onClick={()=>setMobile(true)} span={true}  smooth={true} to="hero" >Home</Link></li>
            <li><Link onClick={()=>setMobile(true)} span={true}  smooth={true} to="programs" >Programs</Link></li>
            <li><Link onClick={()=>setMobile(true)} span={true}  smooth={true} to="reasons" >Why Us</Link></li>
            <li><Link onClick={()=>setMobile(true)} span={true}  smooth={true} to="plans" >Plans</Link></li>
            <li><Link onClick={()=>setMobile(true)} span={true}  smooth={true} to="testimonials">Testimonials</Link></li>
       </ul>
         }

        
       
    </div>
  );
};

export default Header;
