import React from "react";
import "./Programs.css";
import rightArrow from "../../assets/rightArrow.png";
import programs from "../../data/programsData";
const Programs = () => {
  return (
    <div className="programs" id="programs">
      <div className="programs-header">
        <span className="stroke-text">Explore Our</span>
        <span>Program</span>
        <span className="stroke-text">To Shape you</span>
      </div>
      <div className="program-category">
        {programs.map((program, i) => {
          return (
            <div key={i} className="category">
              {program.image}
              <span>{program.heading}</span>
              <span>{program.details}</span>
              <div className="join-now">
                <span>Join Now</span>
                <img src={rightArrow} alt="" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Programs;
